export default {
  // start LOGIN
  APPLE_AUTH_PENDING: "APPLE_AUTH_PENDING",
  APPLE_AUTH_SUCCESS: "APPLE_AUTH_SUCCESS",
  APPLE_AUTH_ERROR: "APPLE_AUTH_ERROR",
  FACEBOOK_AUTH_PENDING: "FACEBOOK_AUTH_PENDING",
  FACEBOOK_AUTH_SUCCESS: "FACEBOOK_AUTH_SUCCESS",
  FACEBOOK_AUTH_ERROR: "FACEBOOK_AUTH_ERROR",
  GOOGLE_AUTH_PENDING: "GOOGLE_AUTH_PENDING",
  GOOGLE_AUTH_SUCCESS: "GOOGLE_AUTH_SUCCESS",
  GOOGLE_AUTH_ERROR: "GOOGLE_AUTH_ERROR",
  LOGIN_PENDING: "LOGIN_PENDING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_WEB_PLAYER_PENDING: "LOGIN_WEB_PLAYER_PENDING",
  LOGIN_WEB_PLAYER_SUCCESS: "LOGIN_WEB_PLAYER_SUCCESS",
  LOGIN_WEB_PLAYER_ERROR: "LOGIN_WEB_PLAYER_ERROR",
  // end LOGIN

  // start lock
  UPDATE_GLOBAL_LOADER: "UPDATE_GLOBAL_LOADER",
  // end lock

  // start LOGOUT
  LOGOUT_PENDING: "LOGOUT_PENDING",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",
  RESET_GLOBAL_STATE: "RESET_GLOBAL_STATE",
  // end LOGOUT

  ADD_DEVICE_PENDING: "ADD_DEVICE_PENDING",
  ADD_DEVICE_SUCCESS: "ADD_DEVICE_SUCCESS",
  ADD_DEVICE_ERROR: "ADD_DEVICE_ERROR",
  REGISTER_DEVICE_PENDING: "REGISTER_DEVICE_PENDING",
  REGISTER_DEVICE_SUCCESS: "REGISTER_DEVICE_SUCCESS",
  REGISTER_DEVICE_ERROR: "REMOVE_DEVICE_ERROR",
  REMOVE_DEVICE_PENDING: "REMOVE_DEVICE_PENDING",
  REMOVE_DEVICE_SUCCESS: "REMOVE_DEVICE_SUCCESS",
  REMOVE_DEVICE_ERROR: "REMOVE_DEVICE_ERROR",
  GET_DEVICES_PENDING: "GET_DEVICES_PENDING",
  GET_DEVICES_SUCCESS: "GET_DEVICES_SUCCESS",
  GET_DEVICES_ERROR: "GET_DEVICES_ERROR",

  // Account
  GET_ACCOUNT_DETAILS_PENDING: "GET_ACCOUNT_DETAILS_PENDING",
  GET_ACCOUNT_DETAILS_SUCCESS: "GET_ACCOUNT_DETAILS_SUCCESS",
  GET_ACCOUNT_DETAILS_ERROR: "GET_ACCOUNT_DETAILS_ERROR",
  RESEND_EMAIL_CONFIRMATION_PENDING: "RESEND_EMAIL_CONFIRMATION_PENDING",
  RESEND_EMAIL_CONFIRMATION_SUCCESS: "RESEND_EMAIL_CONFIRMATION_SUCCESS",
  RESEND_EMAIL_CONFIRMATION_ERROR: "RESEND_EMAIL_CONFIRMATION_ERROR",
  CHANGE_DETAILS_PENDING: "CHANGE_DETAILS_PENDING",
  CHANGE_DETAILS_SUCCESS: "CHANGE_DETAILS_SUCCESS",
  CHANGE_DETAILS_ERROR: "CHANGE_DETAILS_ERROR",
  CHANGE_ADDRESS_PENDING: "CHANGE_ADDRESS_PENDING",
  CHANGE_ADDRESS_SUCCESS: "CHANGE_ADDRESS_SUCCESS",
  CHANGE_ADDRESS_ERROR: "CHANGE_ADDRESS_ERROR",
  UPDATE_PASSWORD_PENDING: "UPDATE_PASSWORD_PENDING",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR: "UPDATE_PASSWORD_ERROR",
  GET_PASSWORD_POLICY_PENDING: "GET_PASSWORD_POLICY_PENDING",
  GET_PASSWORD_POLICY_SUCCESS: "GET_PASSWORD_POLICY_SUCCESS",
  GET_PASSWORD_POLICY_ERROR: "GET_PASSWORD_POLICY_ERROR",
  ASK_RESET_PASSWORD_RESET: "ASK_RESET_PASSWORD_RESET",
  ASK_RESET_PASSWORD_PENDING: "ASK_RESET_PASSWORD_PENDING",
  ASK_RESET_PASSWORD_SUCCESS: "ASK_RESET_PASSWORD_SUCCESS",
  ASK_RESET_PASSWORD_ERROR: "ASK_RESET_PASSWORD_ERROR",
  RESET_PASSWORD_RESET: "RESET_PASSWORD_RESET",
  RESET_PASSWORD_PENDING: "RESET_PASSWORD_PENDING",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  GET_PARENTAL_CODE_PENDING: "GET_PARENTAL_CODE_PENDING",
  GET_PARENTAL_CODE_SUCCESS: "GET_PARENTAL_CODE_SUCCESS",
  GET_PARENTAL_CODE_ERROR: "GET_PARENTAL_CODE_ERROR",
  SET_ADULT_CODE_PENDING: "SET_ADULT_CODE_PENDING",
  SET_ADULT_CODE_SUCCESS: "SET_ADULT_CODE_SUCCESS",
  SET_ADULT_CODE_ERROR: "SET_ADULT_CODE_ERROR",
  VALIDATE_ADULT_CODE_PENDING: "VALIDATE_ADULT_CODE_PENDING",
  VALIDATE_ADULT_CODE_SUCCESS: "VALIDATE_ADULT_CODE_SUCCESS",
  VALIDATE_ADULT_CODE_ERROR: "VALIDATE_ADULT_CODE_ERROR",
  SET_PURCHASE_CODE_PENDING: "SET_PURCHASE_CODE_PENDING",
  SET_PURCHASE_CODE_SUCCESS: "SET_PURCHASE_CODE_SUCCESS",
  SET_PURCHASE_CODE_ERROR: "SET_PURCHASE_CODE_ERROR",
  GET_PURCHASE_CODE_PENDING: "GET_PURCHASE_CODE_PENDING",
  GET_PURCHASE_CODE_SUCCESS: "GET_PURCHASE_CODE_SUCCESS",
  GET_PURCHASE_CODE_ERROR: "GET_PURCHASE_CODE_ERROR",
  VALIDATE_PURCHASE_CODE_PENDING: "VALIDATE_PURCHASE_CODE_PENDING",
  VALIDATE_PURCHASE_CODE_SUCCESS: "VALIDATE_PURCHASE_CODE_SUCCESS",
  VALIDATE_PURCHASE_CODE_ERROR: "VALIDATE_PURCHASE_CODE_ERROR",
  VALIDATE_ACCOUNT_PENDING: "VALIDATE_ACCOUNT_PENDING",
  VALIDATE_ACCOUNT_SUCCESS: "VALIDATE_ACCOUNT_SUCCESS",
  VALIDATE_ACCOUNT_ERROR: "VALIDATE_ACCOUNT_ERROR",
  GET_INVOICES_PENDING: "GET_INVOICES_PENDING",
  GET_INVOICES_SUCCESS: "GET_INVOICES_SUCCESS",
  GET_INVOICES_ERROR: "GET_INVOICES_ERROR",
  POST_ANALYTICS_PENDING: "POST_ANALYTICS_PENDING",
  POST_ANALYTICS_SUCCESS: "POST_ANALYTICS_SUCCESS",
  POST_ANALYTICS_ERROR: "POST_ANALYTICS_ERROR",
  PROFILE_PENDING: "PROFILE_PENDING",
  PROFILE_SUCCESS: "PROFILE_SUCCESS",
  PROFILE_ERROR: "PROFILE_ERROR",
  POST_PROFILE_PENDING: "POST_PROFILE_PENDING",
  POST_PROFILE_SUCCESS: "POST_PROFILE_SUCCESS",
  POST_PROFILE_ERROR: "POST_PROFILE_ERROR",
  PUT_PROFILE_PENDING: "PUT_PROFILE_PENDING",
  PUT_PROFILE_SUCCESS: "PUT_PROFILE_SUCCESS",
  PUT_PROFILE_ERROR: "PUT_PROFILE_ERROR",
  DELETE_PROFILE_PENDING: "DELETE_PROFILE_PENDING",
  DELETE_PROFILE_SUCCESS: "DELETE_PROFILE_SUCCESS",
  DELETE_PROFILE_ERROR: "DELETE_PROFILE_ERROR",
  PROFILE_ACTIVE_PENDING: "PROFILE_ACTIVE_PENDING",
  PROFILE_ACTIVE_SUCCESS: "PROFILE_ACTIVE_SUCCESS",
  PROFILE_ACTIVE_ERROR: "PROFILE_ACTIVE_ERROR",
  SET_PROFILE_ACTIVE_PENDING: "SET_PROFILE_ACTIVE_PENDING",
  SET_PROFILE_ACTIVE_SUCCESS: "SET_PROFILE_ACTIVE_SUCCESS",
  SET_PROFILE_ACTIVE_ERROR: "SET_PROFILE_ACTIVE_ERROR",
  PROFILE_ACTIVE_LOADING_COMPLETED: "PROFILE_ACTIVE_LOADING_COMPLETED",
  AVATARS_PENDING: "AVATARS_PENDING",
  AVATARS_SUCCESS: "AVATARS_SUCCESS",
  AVATARS_ERROR: "AVATARS_ERROR",
  REST_ACCOUNT_STORE: "REST_ACCOUNT_STORE",
  REQUEST_EXPORT_ACCOUNT_PENDING: "REQUEST_EXPORT_ACCOUNT_PENDING",
  REQUEST_EXPORT_ACCOUNT_SUCCESS: "REQUEST_EXPORT_ACCOUNT_SUCCESS",
  REQUEST_EXPORT_ACCOUNT_ERROR: "REQUEST_EXPORT_ACCOUNT_ERROR",

  // start CONTENT
  HOMECONTENT_PENDING: "HOMECONTENT_PENDING",
  HOMECONTENT_SUCCESS: "HOMECONTENT_SUCCESS",
  HOMECONTENT_ERROR: "HOMECONTENT_ERROR",

  // VOD listContent actions
  VOD_LISTCONTENT_PENDING: "VOD_LISTCONTENT_PENDING",
  VOD_LISTCONTENT_SUCCESS: "VOD_LISTCONTENT_SUCCESS",
  VOD_LISTCONTENT_ERROR: "VOD_LISTCONTENT_ERROR",
  VOD_RESET: "VOD_RESET",
  VOD_SUBCATEGORY_LIST_SUCCESS: "VOD_SUBCATEGORY_LIST_SUCCESS",

  // CATCHUP listContent actions
  CATCHUP_LISTCONTENT_PENDING: "CATCHUP_LISTCONTENT_PENDING",
  CATCHUP_LISTCONTENT_SUCCESS: "CATCHUP_LISTCONTENT_SUCCESS",
  CATCHUP_LISTCONTENT_ERROR: "CATCHUP_LISTCONTENT_ERROR",
  CATCHUP_RESET: "CATCHUP_RESET",

  // TVShow listContent actions
  TVSHOW_LIST_CONTENT_PENDING: "TVSHOW_LIST_CONTENT_PENDING",
  TVSHOW_LIST_CONTENT_SUCCESS: "TVSHOW_LIST_CONTENT_SUCCESS",
  TVSHOW_LIST_CONTENT_ERROR: "TVSHOW_LIST_CONTENT_ERROR",
  TVSHOW_EPISODES_LIST_CONTENT_PENDING: "TVSHOW_EPISODES_LIST_CONTENT_PENDING",
  TVSHOW_EPISODES_LIST_CONTENT_SUCCESS: "TVSHOW_EPISODES_LIST_CONTENT_SUCCESS",
  TVSHOW_EPISODES_LIST_CONTENT_ERROR: "TVSHOW_EPISODES_LIST_CONTENT_ERROR",

  // Search
  GET_SEARCH_PENDING: "GET_SEARCH_PENDING",
  GET_SEARCH_SUCCESS: "GET_SEARCH_SUCCESS",
  GET_SEARCH_ERROR: "GET_SEARCH_ERROR",
  RESET_SEARCH_RESULTS: "RESET_SEARCH_RESULTS",

  // EPG actions
  EPG_PENDING: "EPG_PENDING",
  EPG_SUCCESS: "EPG_SUCCESS",
  EPG_ERROR: "EPG_ERROR",
  EPG_RESET: "EPG_RESET",
  EPG_DETAIL_PENDING: "EPG_DETAIL_PENDING",
  EPG_DETAIL_SUCCESS: "EPG_DETAIL_SUCCESS",
  EPG_DETAIL_ERROR: "EPG_DETAIL_ERROR",
  RESET_EPG_DETAIL: "RESET_EPG_DETAIL",
  // end CONTENT

  // EPG actions
  LISTCHANNELS_PENDING: "LISTCHANNELS_PENDING",
  LISTCHANNELS_SUCCESS: "LISTCHANNELS_SUCCESS",
  LISTCHANNELS_ERROR: "LISTCHANNELS_ERROR",

  //Favorites actions
  FAVORITES_PENDING: "FAVORITES_PENDING",
  FAVORITES_SUCCESS: "FAVORITES_SUCCESS",
  ADULT_FAVORITES_SUCCESS: "ADULT_FAVORITES_SUCCESS",
  FAVORITES_ERROR: "FAVORITES_ERROR",
  ADD_FAVORITES_PENDING: "ADD_FAVORITES_PENDING",
  ADD_FAVORITES_SUCCESS: "ADD_FAVORITES_SUCCESS",
  ADD_FAVORITES_ERROR: "ADD_FAVORITES_ERROR",
  REMOVE_FAVORITES_PENDING: "REMOVE_FAVORITES_PENDING",
  REMOVE_FAVORITES_SUCCESS: "REMOVE_FAVORITES_SUCCESS",
  REMOVE_FAVORITES_ERROR: "REMOVE_FAVORITES_ERROR",

  //Favorites channels actions
  FAVORITES_CHANNELS_PENDING: "FAVORITES_CHANNELS_PENDING",
  FAVORITES_CHANNELS_SUCCESS: "FAVORITES_CHANNELS_SUCCESS",
  FAVORITES_CHANNELS_ERROR: "FAVORITES_CHANNELS_ERROR",
  ADD_FAVORITES_CHANNELS_PENDING: "ADD_FAVORITES_CHANNELS_PENDING",
  ADD_FAVORITES_CHANNELS_SUCCESS: "ADD_FAVORITES_CHANNELS_SUCCESS",
  ADD_FAVORITES_CHANNELS_ERROR: "ADD_FAVORITES_CHANNELS_ERROR",
  REMOVE_FAVORITES_CHANNELS_PENDING: "REMOVE_FAVORITES_CHANNELS_PENDING",
  REMOVE_FAVORITES_CHANNELS_SUCCESS: "REMOVE_FAVORITES_CHANNELS_SUCCESS",
  REMOVE_FAVORITES_CHANNELS_ERROR: "REMOVE_FAVORITES_CHANNELS_ERROR",

  //Assets actions
  ASSETS_PENDING: "ASSETS_PENDING",
  ASSETS_SUCCESS: "ASSETS_SUCCESS",
  ASSETS_ERROR: "ASSETS_ERROR",
  ASSETS_NEXT_EPISODE_PENDING: "ASSETS_NEXT_EPISODE_PENDING",
  ASSETS_NEXT_EPISODE_SUCCESS: "ASSETS_NEXT_EPISODE_SUCCESS",
  ASSETS_NEXT_EPISODE_ERROR: "ASSETS_NEXT_EPISODE_ERROR",
  ASSET_RESET: "ASSET_RESET",
  ASSET_SCORE_PENDING: "ASSET_SCORE_PENDING",
  ASSET_SCORE_SUCCESS: "ASSET_SCORE_SUCCESS",
  ASSET_SCORE_ERROR: "ASSET_SCORE_ERROR",
  CHANGE_SCORE_ASSET_PENDING: "CHANGE_SCORE_ASSET_PENDING",
  CHANGE_SCORE_ASSET_SUCCESS: "CHANGE_SCORE_ASSET_SUCCESS",
  CHANGE_SCORE_ASSET_ERROR: "CHANGE_SCORE_ASSET_ERROR",
  ACTIVE_ASSETS_PENDING: "ACTIVE_ASSETS_PENDING",
  ACTIVE_ASSETS_SUCCESS: "ACTIVE_ASSETS_SUCCESS",
  ACTIVE_ASSETS_ERROR: "ACTIVE_ASSETS_ERRORS",
  ACTIVE_ASSETS_TVOD_PENDING: "ACTIVE_ASSETS_TVOD_PENDING",
  ACTIVE_ASSETS_TVOD_SUCCESS: "ACTIVE_ASSETS_TVOD_SUCCESS",
  ACTIVE_ASSETS_TVOD_ERROR: "ACTIVE_ASSETS_TVOD_ERRORS",
  READ_TRAILER_PENDING: "READ_TRAILER_PENDING",
  READ_TRAILER_SUCCESS: "READ_TRAILER_SUCCESS",
  READ_TRAILER_ERROR: "READ_TRAILER_ERROR",
  READ_TRAILER_RESET: "READ_TRAILER_RESET",
  READ_SLIDE_TRAILER_PENDING: "READ_SLIDE_TRAILER_PENDING",
  READ_SLIDE_TRAILER_SUCCESS: "READ_SLIDETRAILER_SUCCESS",
  READ_SLIDE_TRAILER_ERROR: "READ_SLIDE_TRAILER_ERROR",
  READ_SLIDE_TRAILER_RESET: "READ_SLIDE_TRAILER_RESET",
  ASSET_MONOLOLIST_RECOMMENDATION_PENDING: "ASSET_MONOLOLIST_RECOMMENDATION_PENDING",
  ASSET_MONOLOLIST_RECOMMENDATION_SUCCESS: "ASSET_MONOLOLIST_RECOMMENDATION_SUCCESS",
  ASSET_MONOLOLIST_RECOMMENDATION_ERROR: "ASSET_MONOLOLIST_RECOMMENDATION_ERROR",
  ASSET_MULTIFACET_RECOMMENDATION_PENDING: "ASSET_MULTIFACET_RECOMMENDATION_PENDING",
  ASSET_MULTIFACET_RECOMMENDATION_SUCCESS: "ASSET_MULTIFACET_RECOMMENDATION_SUCCESS",
  ASSET_MULTIFACET_RECOMMENDATION_ERROR: "ASSET_MULTIFACET_RECOMMENDATION_ERROR",
  RESET_ACTIVE_ASSETS: "RESET_ACTIVE_ASSETS",
  VISIBILITY_SHOW_SLIDER_TRAILER: "VISIBILITY_SHOW_SLIDER_TRAILER",

  RECORD_PENDING: "RECORD_PENDING",
  RECORD_SUCCESS: "RECORD_SUCCESS",
  RECORD_ERROR: "RECORD_ERROR",

  QUOTA_PENDING: "QUOTA_PENDING",
  QUOTA_SUCCESS: "QUOTA_SUCCESS",
  QUOTA_ERROR: "QUOTA_ERROR",
  // end CONTENT

  // start REFERENCE
  HIERARCHY_PENDING: "HIERARCHY_PENDING",
  HIERARCHY_SUCCESS: "HIERARCHY_SUCCESS",
  HIERARCHY_ERROR: "HIERARCHY_ERROR",

  MENUORDER_PENDING: "MENUORDER_PENDING",
  MENUORDER_SUCCESS: "MENUORDER_SUCCESS",
  MENUORDER_ERROR: "MENUORDER_ERROR",

  FAQ_PENDING: "FAQ_PENDING",
  FAQ_SUCCESS: "FAQ_SUCCESS",
  FAQ_ERROR: "FAQ_ERROR",

  // end REFERENCE

  // PLAYER
  TEST_ASSET_PENDING: "TEST_ASSET_PENDING",
  TEST_ASSET_SUCCESS: "TEST_ASSET_SUCCESS",
  TEST_ASSET_ERROR: "TEST_ASSET_ERROR",

  BUY_ASSET_PENDING: "BUY_ASSET_PENDING",
  BUY_ASSET_SUCCESS: "BUY_ASSET_SUCCESS",
  BUY_ASSET_ERROR: "BUY_ASSET_ERROR",

  READ_ASSET_PENDING: "READ_ASSET_PENDING",
  READ_ASSET_SUCCESS: "READ_ASSET_SUCCESS",
  READ_ASSET_ERROR: "READ_ASSET_ERROR",

  GET_VOD_LICENSE_PENDING: "GET_VOD_LICENSE_PENDING",
  GET_VOD_LICENSE_SUCCESS: "GET_VOD_LICENSE_SUCCESS",
  GET_VOD_LICENSE_ERROR: "GET_VOD_LICENSE_ERROR",

  POST_SEEK_ASSET_PENDING: "POST_SEEK_ASSET_PENDING",
  POST_SEEK_ASSET_SUCCESS: "POST_SEEK_ASSET_SUCCESS",
  POST_SEEK_ASSET_ERROR: "POST_SEEK_ASSET_ERROR",

  POST_CHANNEL_STREAM_PENDING: "POST_CHANNEL_STREAM_PENDING",
  POST_CHANNEL_STREAM_SUCCESS: "POST_CHANNEL_STREAM_SUCCESS",
  POST_CHANNEL_STREAM_ERROR: "POST_CHANNEL_STREAM_ERROR",

  POST_ASK_LICENSE_WV_PENDING: "POST_ASK_LICENSE_WV_PENDING",
  POST_ASK_LICENSE_WV_SUCCESS: "POST_ASK_LICENSE_WV_SUCCESS",
  POST_ASK_LICENSE_WV_ERROR: "POST_ASK_LICENSE_WV_ERROR",

  RESET_PLAYER_INFOS: "RESET_PLAYER_INFOS",

  RESTART_PLAYER_LIVE: "RESTART_PLAYER_LIVE",

  SET_TRAILER_STATUS: "SET_TRAILER_STATUS",

  LIST_ASSET_TAGS_PENDING: "LIST_ASSET_TAGS_PENDING",
  LIST_ASSET_TAGS_SUCCESS: "LIST_ASSET_TAGS_SUCCESS",
  LIST_ASSET_TAGS_ERROR: "LIST_ASSET_TAGS_ERROR",

  // SUBSCRIPTION
  CREATE_ACCOUNT_PENDING: "CREATE_ACCOUNT_PENDING",
  CREATE_ACCOUNT_SUCCESS: "CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_ERROR: "CREATE_ACCOUNT_ERROR",
  ADD_PRODUCTS_PENDING: "ADD_PRODUCTS_PENDING",
  ADD_PRODUCTS_SUCCESS: "ADD_PRODUCTS_SUCCESS",
  ADD_PRODUCTS_ERROR: "ADD_PRODUCTS_ERROR",
  ADD_OPTIONS_PENDING: "ADD_OPTIONS_PENDING",
  ADD_OPTIONS_SUCCESS: "ADD_OPTIONS_SUCCESS",
  ADD_OPTIONS_ERROR: "ADD_OPTIONS_ERROR",
  REMOVE_OPTIONS_PENDING: "REMOVE_OPTIONS_PENDING",
  REMOVE_OPTIONS_SUCCESS: "REMOVE_OPTIONS_SUCCESS",
  REMOVE_OPTIONS_ERROR: "REMOVE_OPTIONS_ERROR",
  ASK_PAYMENT_PENDING: "ASK_PAYMENT_PENDING",
  ASK_PAYMENT_SUCCESS: "ASK_PAYMENT_SUCCESS",
  ASK_PAYMENT_ERROR: "ASK_PAYMENT_ERROR",
  EXECUTE_PAYMENT_PENDING: "EXECUTE_PAYMENT_PENDING",
  EXECUTE_PAYMENT_SUCCESS: "EXECUTE_PAYMENT_SUCCESS",
  EXECUTE_PAYMENT_ERROR: "EXECUTE_PAYMENT_ERROR",
  CHECK_ORDER_STATUS_PENDING: "CHECK_ORDER_STATUS_PENDING",
  CHECK_ORDER_STATUS_SUCCESS: "CHECK_ORDER_STATUS_SUCCESS",
  ORDER_STATUS_RESET: "ORDER_STATUS_RESET",
  CHECK_ORDER_STATUS_ERROR: "CHECK_ORDER_STATUS_ERROR",
  VALIDATE_VOUCHER_PENDING: "VALIDATE_VOUCHER_PENDING",
  VALIDATE_VOUCHER_SUCCESS: "VALIDATE_VOUCHER_SUCCESS",
  VALIDATE_VOUCHER_ERROR: "VALIDATE_VOUCHER_ERROR",
  RESET_VOUCHER_DATA: "RESET_VOUCHER_DATA",
  ASK_CHANGE_BANK_INFORMATION_PENDING: "ASK_CHANGE_BANK_INFORMATION_PENDING",
  ASK_CHANGE_BANK_INFORMATION_SUCCESS: "ASK_CHANGE_BANK_INFORMATION_SUCCESS",
  ASK_CHANGE_BANK_INFORMATION_ERROR: "ASK_CHANGE_BANK_INFORMATION_ERROR",
  AVAILABLE_OFFERS_PENDING: "AVAILABLE_OFFERS_PENDING",
  AVAILABLE_OFFERS_SUCCESS: "AVAILABLE_OFFERS_SUCCESS",
  AVAILABLE_OFFERS_ERROR: "AVAILABLE_OFFERS_ERROR",
  PPV_OFFERS_PENDING: "PPV_OFFERS_PENDING",
  PPV_OFFERS_SUCCESS: "PPV_OFFERS_SUCCESS",
  PPV_OFFERS_ERROR: "PPV_OFFERS_ERROR",
  TRANSACTIONAL_OFFERS_PENDING: "TRANSACTIONAL_OFFERS_PENDING",
  TRANSACTIONAL_OFFERS_SUCCESS: "TRANSACTIONAL_OFFERS_SUCCESS",
  TRANSACTIONAL_OFFERS_ERROR: "TRANSACTIONAL_OFFERS_ERROR",
  AVAILABLE_OFFERS_VOUCHER_PENDING: "AVAILABLE_OFFERS_VOUCHER_PENDING",
  AVAILABLE_OFFERS_VOUCHER_SUCCESS: "AVAILABLE_OFFERS_VOUCHER_SUCCESS",
  AVAILABLE_OFFERS_VOUCHER_ERROR: "AVAILABLE_OFFERS_VOUCHER_ERROR",
  LIST_OPTIONS_PENDING: "LIST_OPTIONS_PENDING",
  LIST_OPTIONS_SUCCESS: "LIST_OPTIONS_SUCCESS",
  LIST_OPTIONS_ERROR: "LIST_OPTIONS_ERROR",
  LIST_PPV_OPTIONS_PENDING: "LIST_PPV_OPTIONS_PENDING",
  LIST_PPV_OPTIONS_SUCCESS: "LIST_PPV_OPTIONS_SUCCESS",
  LIST_PPV_OPTIONS_ERROR: "LIST_PPV_OPTIONS_ERROR",
  ACTIVEPRODUCTS_PENDING: "ACTIVEPRODUCTS_PENDING",
  ACTIVEPRODUCTS_SUCCESS: "ACTIVEPRODUCTS_SUCCESS",
  ACTIVEPRODUCTS_ERROR: "ACTIVEPRODUCTS_ERROR",
  OPTION_VALIDITY_PENDING: "OPTION_VALIDITY_PENDING",
  OPTION_VALIDITY_SUCCESS: "OPTION_VALIDITY_SUCCESS",
  OPTION_VALIDITY_ERROR: "OPTION_VALIDITY_ERROR",
  LIST_PAYMENT_METHODS_PENDING: "LIST_PAYMENT_METHODS_PENDING",
  LIST_PAYMENT_METHODS_SUCCESS: "LIST_PAYMENT_METHODS_SUCCESS",
  LIST_PAYMENT_METHODS_ERROR: "LIST_PAYMENT_METHODS_ERROR",
  LIST_COUNTRY_PENDING: "LIST_COUNTRY_PENDING",
  LIST_COUNTRY_SUCCESS: "LIST_COUNTRY_SUCCESS",
  LIST_COUNTRY_ERROR: "LIST_COUNTRY_ERROR",
  DOCUMENT_PENDING: "DOCUMENT_PENDING",
  DOCUMENT_SUCCESS: "DOCUMENT_SUCCESS",
  DOCUMENT_ERROR: "DOCUMENT_ERROR",
  SET_ON_SIGNUP_STEPS: "SET_ON_SIGNUP_STEPS",
  SET_CURRENT_TRAILER_ID_SLIDER: "SET_CURRENT_TRAILER_ID_SLIDER",
  RESET_CURRENT_TRAILER_ID_SLIDER: "RESET_CURRENT_TRAILER_ID_SLIDER",
  RESET_ASSET_TRAILER_OBJECT: "RESET_ASSET_TRAILER_OBJECT",
  RESTART_ASSETS_VIDEO: "RESTART_ASSETS_VIDEO",
  SET_SERVER_ERROR: "SET_SERVER_ERROR",
  RESET_SERVER_ERROR: "RESET_SERVER_ERROR",
  SSO_GRNT_CODE_RECEIVED: "SSO_GRNT_CODE_RECEIVED",
  GET_BILLING_DASHBOARD_ACCESS_PENDING: "GET_BILLING_DASHBOARD_ACCESS_PENDING",
  GET_BILLING_DASHBOARD_ACCESS_SUCCESS: "GET_BILLING_DASHBOARD_ACCESS_SUCCESS",
  GET_BILLING_DASHBOARD_ACCESS_ERROR: "GET_BILLING_DASHBOARD_ACCESS_ERROR",

  // Delete account
  CHECK_PASSWORD_PENDING: "CHECK_PASSWORD_PENDING",
  CHECK_PASSWORD_SUCCESS: "CHECK_PASSWORD_SUCCESS",
  CHECK_PASSWORD_ERROR: "CHECK_PASSWORD_ERROR",
  DELETE_ACCOUNT_PENDING: "DELETE_ACCOUNT_PENDING",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_ERROR: "DELETE_ACCOUNT_ERROR",

  // sync from tve (sso)
  SYNC_FROM_TVE_PENDING: "SYNC_FROM_TVE_PENDING",
  SYNC_FROM_TVE_SUCCESS: "SYNC_FROM_TVE_SUCCESS",
  SYNC_FROM_TVE_ERROR: "SYNC_FROM_TVE_ERROR",
  REQUEST_EXPORT_PENDING: "REQUEST_EXPORT_PENDING",
  REQUEST_EXPORT_SUCCESS: "REQUEST_EXPORT_SUCCESS",
  REQUEST_EXPORT_ERROR: "REQUEST_EXPORT_ERROR",
  GET_EXPORT_PENDING: "GET_EXPORT_PENDING",
  GET_EXPORT_SUCCESS: "GET_EXPORT_SUCCESS",
  GET_EXPORT_ERROR: "GET_EXPORT_ERROR",

  // login by pairing
  LOGIN_BY_PAIRING_PENDING: "LOGIN_BY_PAIRING_PENDING",
  LOGIN_BY_PAIRING_SUCCESS: "LOGIN_BY_PAIRING_SUCCESS",
  LOGIN_BY_PAIRING_ERROR: "LOGIN_BY_PAIRING_ERROR",

  SET_APP_HISTORY_LINK: "SET_APP_HISTORY_LINK",
  RESET_APP_HISTORY_LINK: "RESET_APP_HISTORY_LINK",
};
