import actionTypes from "../consts/actionTypes";

const initialState = {
  home: {
    loading: true,
    data: [],
  },
  epg: {
    loading: true,
    data: [],
  },
  vod: {
    loading: true,
    data: [],
  },
  catchup: {
    loading: true,
    data: [],
  },
  favorites: {
    loading: false,
    data: undefined,
    adultData: undefined,
  },
  favoritesChannels: {
    loading: false,
    data: undefined,
  },
  asset: {
    loading: true,
    data: undefined,
    error: undefined,
    nextEpisode: undefined,
    trailer: { loading: true },
    monolistRecommendation: {},
    multifacetRecommendation: {},
    sliderTrailer: { loading: true },
  },
  assetEPG: {
    loading: true,
    data: undefined,
  },
  tvshow: {
    loading: true,
    data: undefined,
  },
  activeAssets: {
    loading: true,
    data: [],
  },
  activeAssetsTVOD: {
    loading: true,
    data: [],
  },
  records: {
    loading: true,
    count: 0,
    data: [],
  },
  quota: {
    loading: true,
    data: {},
    error: undefined,
  },
  showSliderTrailer: false,
  currentTrailerIdSlider: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HOMECONTENT_PENDING:
      return {
        ...state,
        home: {
          loading: true,
          data: [],
        },
      };
    case actionTypes.HOMECONTENT_SUCCESS:
      return {
        ...state,
        home: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.HOMECONTENT_ERROR:
      return {
        ...state,
        home: {
          error: action.meta,
        },
      };
    case actionTypes.VOD_LISTCONTENT_PENDING:
      return {
        ...state,
        vod: initialState.vod,
      };
    case actionTypes.VOD_LISTCONTENT_SUCCESS:
      return {
        ...state,
        vod: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.VOD_LISTCONTENT_ERROR:
      return {
        ...state,
        vod: {
          error: action.meta,
        },
      };
    case actionTypes.VOD_RESET:
      return {
        ...state,
        vod: initialState.vod,
      };
    case actionTypes.EPG_RESET:
      return {
        ...state,
        epg: initialState.epg,
      };
    case actionTypes.EPG_PENDING:
      return {
        ...state,
        epg: {
          loading: true,
          data: state.epg.data,
        },
      };
    case actionTypes.EPG_SUCCESS:
      return {
        ...state,
        epg: {
          loading: false,
          data: action.payload.epg,
        },
      };
    case actionTypes.EPG_ERROR:
      return {
        ...state,
        epg: {
          loading: false,
          error: action.meta,
        },
      };
    case actionTypes.RESET_EPG_DETAIL:
      return {
        ...state,
        assetEPG: initialState.assetEPG,
      };
    case actionTypes.EPG_DETAIL_PENDING:
      return {
        ...state,
      };
    case actionTypes.EPG_DETAIL_SUCCESS:
      return {
        ...state,
        assetEPG: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.EPG_DETAIL_ERROR:
      return {
        ...state,
        assetEPG: {
          error: action.meta,
        },
      };
    case actionTypes.FAVORITES_PENDING:
      return {
        ...state,
        favorites: {
          loading: true,
          data: state.favorites.data,
          adultData: state.favorites.adultData,
        },
      };
    case actionTypes.FAVORITES_SUCCESS:
      return {
        ...state,
        favorites: {
          loading: false,
          data: action.payload.favoriteAssets,
          adultData: state.favorites.adultData,
        },
      };
    case actionTypes.ADULT_FAVORITES_SUCCESS:
      return {
        ...state,
        favorites: {
          loading: false,
          data: state.favorites.data,
          adultData: action.payload.favoriteAssets,
        },
      };
    case actionTypes.FAVORITES_ERROR:
      return {
        ...state,
        favorites: {
          loading: false,
          error: action.meta,
        },
      };
    case actionTypes.FAVORITES_CHANNELS_PENDING:
      return {
        ...state,
        favoritesChannels: {
          ...state.favoritesChannels,
          loading: true,
        },
      };
    case actionTypes.FAVORITES_CHANNELS_SUCCESS:
      return {
        ...state,
        favoritesChannels: {
          loading: false,
          data: action.payload.favoriteChannels,
        },
      };
    case actionTypes.FAVORITES_CHANNELS_ERROR:
      return {
        ...state,
        favoritesChannels: {
          loading: false,
          error: action.meta,
        },
      };
    case actionTypes.ASSETS_PENDING:
      return {
        ...state,
        asset: {
          ...state.asset,
          loading: true,
          nextEpisode: undefined,
        },
      };
    case actionTypes.ASSETS_SUCCESS:
      return {
        ...state,
        asset: {
          ...state.asset,
          loading: false,
          data: action.payload.items[0],
          nextEpisode: undefined,
        },
      };
    case actionTypes.ASSETS_ERROR:
      return {
        ...state,
        asset: {
          error: action.meta,
          assetScore: {
            error: action.meta,
          },
        },
      };
    case actionTypes.ASSETS_NEXT_EPISODE_PENDING:
      return {
        ...state,
      };
    case actionTypes.ASSETS_NEXT_EPISODE_SUCCESS:
      return {
        ...state,
        asset: {
          ...state.asset,
          nextEpisode: action.payload.items[0],
        },
      };
    case actionTypes.ASSETS_NEXT_EPISODE_ERROR:
      return {
        ...state,
      };
    case actionTypes.ASSET_MONOLOLIST_RECOMMENDATION_PENDING:
      return {
        ...state,
      };
    case actionTypes.ASSET_MONOLOLIST_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        asset: {
          ...state.asset,
          monolistRecommendation: action.payload,
        },
      };
    case actionTypes.ASSET_MONOLOLIST_RECOMMENDATION_ERROR:
      return {
        ...state,
        asset: {
          ...state.asset,
          monolistRecommendation: {
            error: action.meta,
          },
        },
      };
    case actionTypes.ASSET_MULTIFACET_RECOMMENDATION_PENDING:
      return {
        ...state,
      };
    case actionTypes.ASSET_MULTIFACET_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        asset: {
          ...state.asset,
          multifacetRecommendation: action.payload,
        },
      };
    case actionTypes.ASSET_MULTIFACET_RECOMMENDATION_ERROR:
      return {
        ...state,
        asset: {
          ...state.asset,
          multifacetRecommendation: {
            error: action.meta,
          },
        },
      };
    case actionTypes.ASSET_RESET:
      return {
        ...state,
        asset: initialState.asset,
      };
    case actionTypes.ASSET_SCORE_PENDING:
      return {
        ...state,
      };
    case actionTypes.ASSET_SCORE_SUCCESS:
      return {
        ...state,
        asset: {
          ...state.asset,
          loading: false,
          data: {
            ...state.asset.data,
            rating: {
              score: action.payload.score,
              average: action.payload.average,
            },
          },
        },
        tvshow: {
          ...state.tvshow,
          loading: false,
          data: {
            ...state.tvshow.data,
            rating: {
              score: action.payload.score,
              average: action.payload.average,
            },
          },
        },
      };
    case actionTypes.ASSET_SCORE_ERROR:
      return {
        ...state,
        asset: {
          error: action.meta,
          rating: {
            error: action.meta,
          },
        },
      };
    case actionTypes.ACTIVE_ASSETS_PENDING:
      return {
        ...state,
      };
    case actionTypes.ACTIVE_ASSETS_SUCCESS:
      return {
        ...state,
        activeAssets: {
          loading: false,
          data: action.payload.activeAssets,
        },
      };
    case actionTypes.ACTIVE_ASSETS_ERROR:
      return {
        ...state,
        activeAssets: {
          error: action.meta,
        },
      };
    case actionTypes.RESET_ACTIVE_ASSETS:
      return {
        ...state,
        activeAssets: {
          loading: true,
          data: initialState.activeAssets.data,
        },
      };
    case actionTypes.ACTIVE_ASSETS_TVOD_PENDING:
      return {
        ...state,
      };
    case actionTypes.ACTIVE_ASSETS_TVOD_SUCCESS:
      return {
        ...state,
        activeAssetsTVOD: {
          loading: false,
          data: action.payload.activeAssets,
        },
      };
    case actionTypes.ACTIVE_ASSETS_TVOD_ERROR:
      return {
        ...state,
        activeAssetsTVOD: {
          error: action.meta,
        },
      };
    case actionTypes.READ_TRAILER_PENDING: {
      const newAsset = state.asset;
      newAsset.trailer = {
        loading: true,
      };
      return {
        ...state,
        asset: newAsset,
      };
    }
    case actionTypes.READ_TRAILER_SUCCESS: {
      const newAsset = state.asset;
      newAsset.trailer = {
        loading: false,
        data: action.payload,
      };
      return {
        ...state,
        asset: newAsset,
      };
    }
    case actionTypes.READ_TRAILER_ERROR: {
      const newAsset = { ...state.asset };
      newAsset.trailer = {
        loading: false,
        error: true,
        message: action.payload?.details?.message || "",
      };
      return {
        ...state,
        asset: newAsset,
      };
    }
    case actionTypes.READ_TRAILER_RESET: {
      const newAsset = state.asset;
      newAsset.trailer = {};
      return {
        ...state,
        asset: newAsset,
      };
    }
    case actionTypes.READ_SLIDE_TRAILER_PENDING: {
      const newAsset = state.asset;
      newAsset.sliderTrailer = {
        loading: true,
      };
      return {
        ...state,
        asset: newAsset,
      };
    }
    case actionTypes.READ_SLIDE_TRAILER_SUCCESS: {
      const newAsset = state.asset;
      newAsset.sliderTrailer = {
        loading: false,
        data: { ...action.payload, trailerID: action.trailerID },
      };
      return {
        ...state,
        asset: newAsset,
      };
    }
    case actionTypes.READ_SLIDE_TRAILER_ERROR: {
      const newAsset = state.asset;
      newAsset.sliderTrailer = {
        loading: false,
        error: true,
      };
      return {
        ...state,
        asset: newAsset,
      };
    }
    case actionTypes.READ_SLIDE_TRAILER_RESET: {
      const newAsset = state.asset;
      newAsset.sliderTrailer = {};
      return {
        ...state,
        asset: newAsset,
      };
    }
    case actionTypes.TVSHOW_LIST_CONTENT_PENDING:
      return {
        ...state,
      };
    case actionTypes.TVSHOW_LIST_CONTENT_SUCCESS: {
      const formattedData = action.payload.items.map((item) => {
        return item.payload;
      });
      return {
        ...state,
        tvshow: {
          loading: false,
          data: formattedData,
        },
      };
    }
    case actionTypes.TVSHOW_LIST_CONTENT_ERROR:
      return {
        ...state,
        tvshow: {
          error: action.meta,
        },
      };
    case actionTypes.TVSHOW_EPISODES_LIST_CONTENT_PENDING:
      return {
        ...state,
      };
    case actionTypes.TVSHOW_EPISODES_LIST_CONTENT_SUCCESS: {
      const data = Object.assign({}, state.tvshow.data);
      data[action.index].assets = action.payload.items.map((item) => {
        return item.payload;
      });
      return {
        ...state,
        tvshow: {
          loading: false,
          data: data,
        },
      };
    }
    case actionTypes.TVSHOW_EPISODES_LIST_CONTENT_ERROR: {
      const dataError = Object.assign({}, state.tvshow.error);
      dataError[action.index].assets = action.meta;
      return {
        ...state,
        tvshow: {
          error: dataError,
        },
      };
    }
    case actionTypes.CATCHUP_LISTCONTENT_PENDING:
      return {
        ...state,
        catchup: initialState.catchup,
      };
    case actionTypes.CATCHUP_LISTCONTENT_SUCCESS:
      return {
        ...state,
        catchup: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.CATCHUP_LISTCONTENT_ERROR:
      return {
        ...state,
        catchup: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.VISIBILITY_SHOW_SLIDER_TRAILER:
      return {
        ...state,
        showSliderTrailer: action.show,
      };
    case actionTypes.SET_CURRENT_TRAILER_ID_SLIDER:
      return {
        ...state,
        currentTrailerIdSlider: action.trailerId,
      };
    case actionTypes.RESET_CURRENT_TRAILER_ID_SLIDER:
      return {
        ...state,
        currentTrailerIdSlider: "",
      };
    case actionTypes.RESET_ASSET_TRAILER_OBJECT:
      // eslint-disable-next-line no-case-declarations
      const { asset } = state;
      asset.trailer = { loading: true };
      return {
        ...state,
        asset,
      };
    case actionTypes.RECORD_PENDING:
      return {
        ...state,
        records: {
          ...state.records,
          loading: true,
        },
      };
    case actionTypes.RECORD_SUCCESS:
      return {
        ...state,
        records: {
          loading: false,
          count: action.payload.count,
          data: action.payload.records,
        },
      };
    case actionTypes.RECORD_ERROR:
      return {
        ...state,
        records: {
          error: action.meta,
        },
      };
    case actionTypes.QUOTA_PENDING:
      return {
        ...state,
        quota: {
          ...state.quota,
          loading: true,
        },
      };
    case actionTypes.QUOTA_SUCCESS:
      return {
        ...state,
        quota: {
          loading: false,
          data: action.payload.npvr,
        },
      };
    case actionTypes.QUOTA_ERROR:
      return {
        ...state,
        quota: {
          loading: false,
          error: action.payload,
        },
      };
    case actionTypes.RESET_GLOBAL_STATE:
      return initialState;
    default:
      return state;
  }
};
